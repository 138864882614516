import "./Card.scss";

type CardComponentProps = {
    children?: any;
    width?: number;
    height?: number;
    onClick?: () => void;
    classes?: string;
}

const Card = ({children, width, height, onClick, classes = ""}: CardComponentProps) => {
    return <div className={"card-container " + classes}
    style={{width: width, height: height}}
    onClick={onClick}>
        {children}
    </div>
}

export default Card;