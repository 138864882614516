import { OpenAIApi, Configuration } from "openai";
import { apiCall } from "./store-util";
import dayjs from "dayjs";

const config = new Configuration({
    organization: "org-rON0ehkoSmFqPXWcLUauDput",
    apiKey: process.env.REACT_APP_OPENAI_APIKEY
});

const openai = new OpenAIApi(config);

export const generateNewWisdom = () => {
    return openai.createChatCompletion({
        model: "gpt-3.5-turbo-0613",
        messages: [
            {role: "system", content: "Write a short message of daily wisdom to be displayed on the front page of a website. Make it less than 100 characters long. No quotes, any extra pronunciation, or additional text."}
        ],
        max_tokens: 100,
        n: 1,
        stream: false
    }).then((r) => {
        return r?.data?.choices[0]?.message?.content || "Error";
    });
}

export const getDailyWisdom = () => {
    return apiCall("get-daily-wisdom").then((response: any) => {
        const res = JSON.parse(response)[0];
        if (dayjs().isSame(dayjs(res.last_updated), 'day')) {
            return res.wisdom;
        }

        return generateNewWisdom().then(msg => {
            if (msg !== "Error") {
                apiCall("set-daily-wisdom", {
                    wisdom: msg
                });
            }
            return msg;
        });
    });
}