import { useEffect, useState } from "react";
import CardComponent from "../../common/CardComponent";
import { TokiPonaWord } from "../../types"
import { shuffleArray } from "../../util";
import Nimi from "./NimiComponent";

type QuizzerComponentProps = {
    words: TokiPonaWord[];
}

const QuizzerComponent = ({words}: QuizzerComponentProps) => {
    const [wordIndex, setWordIndex] = useState<number>(0);
    const [options, setOptions]     = useState<TokiPonaWord[]>([]);
    const [result, setResult]   = useState<"correct"|"incorrect"|null>(null);

    const getOptions = () => {
        const newOptions = [];
        const samples = shuffleArray(words);

        newOptions.push(words[wordIndex]);
        for(let i = 0; newOptions.length < 4 && i < 5; i++) {
            if (!newOptions.includes(samples[i])) {
                newOptions.push(samples[i]);
            }
        }

        setOptions(shuffleArray(newOptions));
    }

    const checkAnswer = (selectedWord: TokiPonaWord) => {
        if (selectedWord.id === words[wordIndex].id) {
            setResult("correct");
        } else {
            setResult("incorrect");
        }
    }

    const getDefinitions = (word: TokiPonaWord) => {
        return Object.entries(word).map((value, index) => value[0] !== "id" && value[0] !== "word" &&
            <div key={index} className="word-definition">
                <span className="part-of-speech">{value[0]}: </span>
                <span className="translation">{value[1]}</span>
            </div>
        );
    }

    useEffect(() => {
        if (result === null) {
            getOptions();
        }
    }, [words, result]);

    return <div className={`quizzer ${result && result}`}>
        <div className="quizzer-question-container">
            <CardComponent>
                <Nimi dictionary={words}>{words[wordIndex].word}</Nimi>
            </CardComponent>
        </div>
        <div className="quizzer-answers-container">
            {options.map((word, index) =>
                <CardComponent classes={word.id === words[wordIndex].id && result ? "correct" : ""}
                key={index}
                onClick={() => checkAnswer(word)}>
                    {getDefinitions(word)}
                </CardComponent>
            )}
        </div>
        {result && <div className="quizzer-footer-container">
            <CardComponent onClick={() => {
                setWordIndex(Math.floor(Math.random() * words.length));
                setResult(null);
            }}>
                Next Question
            </CardComponent>
        </div>}
    </div>
}

export default QuizzerComponent;