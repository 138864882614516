export const apiCall = async(endpoint: string, payload?: any) => {
    return new Promise((resolve, reject) => {
        const xhttp = new XMLHttpRequest();
        xhttp.open(payload ? "POST" : "GET", `https://riley-davis.com/api/${endpoint}.php`);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhttp.setRequestHeader("Authorization", "Bearer " + process.env.REACT_APP_RILEYDAVIS_APIKEY);

        xhttp.onload = (res) => {
            if (xhttp.status === 200) {
                resolve(xhttp.response);
            } else {
                reject("");
            }
        };

        
        if (payload) {
            xhttp.send(JSON.stringify(payload));
        } else {
            xhttp.send();
        }
    })
}