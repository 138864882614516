import "./Index.scss";
import React, { useEffect, useState } from 'react';
import RelaxComponent from './Relax/RelaxComponent';
import HeaderLink from "./HeaderLink";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TokiPonaComponent from "./TokiPona/TokiPonaComponent";
import CustomizationPage from "./CustomizationPage";
import GameComponent from "./Wargame/DivineFavorClient";
import CSSQuickLinks from "./CSSQuickLinks/CSSQuickLinks";
import HomeComponent from "./Home/HomeComponent";

enum TabView {
  Game,
  TokiPona,
  Home,
  Relax,
  CSSQuickLinks
};

type Coords = {
  x: number;
  y: number;
}

const IndexComponent = () => {
  const [tabView, setTabView]           = useState<TabView>(TabView.Home);
  const [customCursor, setCustomCursor] = useState<any>(null);
  const [cursorPos, setCursorPos]       = useState<Coords>({x: 0, y: 0});

  const updateCustomCursorPos = (e: MouseEvent) => {
    if (customCursor) {
      setCursorPos({
        x: e.clientX,
        y: e.clientY
      });
    }
  }

  useEffect(() => {
    document.body.addEventListener("mousemove", updateCustomCursorPos);
  
    return () => {
      document.body.removeEventListener("mousemove", updateCustomCursorPos);
    }
  }, [customCursor]);

  const handleSetCursor = (e: any, newCursor: any) => {
    setCursorPos({x: e.clientX, y: e.clientY});
    setCustomCursor(newCursor);
  };

  return <BrowserRouter>
    <Routes>
      <Route path="*" element={
        <div className={`entire-fucking-website-container
            ${tabView === TabView.Relax && "relax"}
            ${customCursor ? "no-cursor" : ""}`}>
          <div className="navbar">
            <HeaderLink onClick={() => setTabView(TabView.Game)} path="/wargame">Divine Favor (game)</HeaderLink>
            <HeaderLink onClick={() => setTabView(TabView.TokiPona)} path="/toki-pona">Learn Toki Pona</HeaderLink>
            <HeaderLink onClick={() => setTabView(TabView.Home)} path="/">Home</HeaderLink>
            <HeaderLink onClick={() => setTabView(TabView.Relax)} path="/relax">Relax Tool</HeaderLink>
            <HeaderLink onClick={() => setTabView(TabView.CSSQuickLinks)} path="/cssquicklinks">CSS Quick Links</HeaderLink>
            <CustomizationPage onSetCursor={handleSetCursor} closeListener={tabView}/>
          </div>
          <div className="site-body">
            {tabView === TabView.Game && <GameComponent/>}
            {tabView === TabView.TokiPona && <TokiPonaComponent/>}
            {tabView === TabView.Home && <HomeComponent/>}
            {tabView === TabView.Relax && <RelaxComponent/>}
            {tabView === TabView.CSSQuickLinks && <CSSQuickLinks/>}
            {customCursor && <img className="custom-cursor" src={customCursor} alt=""
              style={{
                left: cursorPos.x - 5,
                top: cursorPos.y - 5
              }}/>}
          </div>
        </div>
        }/>
    </Routes>
  </BrowserRouter>
}

export default IndexComponent;