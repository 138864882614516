import { useEffect, useState } from 'react';
import './DivineFavor.scss';
import GameDataDefault from './gamedata-default.json';
import VariableDisplay from './VariableDisplay';
import WheatImg from "./img/wheat.png";
import WoodImg from "./img/wood.png";
import Hexagon from "./img/hexy.png";
import { GameData, Variable } from './wargame_types';

const DivineFavorComponent = () => {
    const [gameData, setGameData] = useState<GameData>();
    const [tick, setTick]         = useState<number>(0);
    
    const rate = (res) => {
        return (isNaN(res) || !isFinite(res)) ? 0 : res;
    };

    const calculate = (cap: number|null, expression): Variable => {
        return {
            cap: cap,
            rate: rate(expression)
        } as Variable;
    };

    const villageCalculations = gameData?.villages.map(vil => ({
        population: calculate(null, vil.resources.food <= 0 ? -(vil.population * 0.1) : 0),
        militia: calculate(vil.population / 4, vil.population / 100),
        food: calculate(vil.structures.farms * 50, -(vil.population / 10)),
        wood: calculate(vil.structures.farms * 25, 0)
    }));

    const gameTick = () => {
        const sum = (val, cal: Variable) => {
            let res = val + cal.rate;
            if (cal.cap && res > cal.cap) {
                res = cal.cap;
            } else if (res <= 0) {
                res = 0;
            }
            return res;
        };

        const newGameData = gameData;

        newGameData.villages = newGameData.villages.map((vil, i) => {
            const calcs = villageCalculations[i];
            const village = {
                ...vil,
                population: sum(vil.population, calcs.population),
                militia: sum(vil.militia, calcs.militia),
                potential: {
                    ...vil.potential,
                    crop: vil.potential.crop + 10,
                    lumber: vil.potential.lumber + 10,
                    ore: vil.potential.ore + 10,
                    shimmer: vil.potential.shimmer + 10                    
                },
                resources: {
                    ...vil.resources,
                    food: sum(vil.resources.food, calcs.food),
                    wood: sum(vil.resources.wood, calcs.wood)
                },
                structures: {
                    ...vil.structures
                }
            }

            // Harvest Logic
            Object.entries(village.potential).map(([key, value]) => {
                if (value > 100) {
                    switch (key){
                        default:
                        case "crop":
                            const foodSurplus = village.resources.food;

                            // Food harvest!
                            village.potential.crop = 0;
                            village.resources.food += vil.structures.farms * 50;

                            if (village.population < foodSurplus) {
                                // Population growth as a result of food surplus
                                const growth = village.population * 1.1;
                                if (growth - village.population  >= 1) {
                                    village.population = growth;
                                } else {
                                    village.population += 1;
                                }
                            } else {
                                // Build a farm
                                if (village.resources.wood >= 25) {
                                    village.resources.wood -= 25;
                                    village.structures.farms += 1;
                                }
                            };

                            break;
                        case "lumber":
                            village.potential.lumber = 0;
                            village.resources.wood += vil.structures.farms * 7;
                            break;
                        case "ore":
                            break;
                        case "shimmer":
                            break;
                    };
                }

                return () => {};
            });

            return village;
        });

        setGameData(newGameData);
    };

    useEffect(() => {
        const tickInterval = setInterval(() => {
            setTick(tick + 1);
            console.log("Tick: ", tick);
            if (gameData && gameData.villages) {
                gameTick();
            }
        }, 1000);

        return () => clearInterval(tickInterval)
    }, [gameTick, gameData]);

    return <div className="game-container">
        {gameData ? <div className="game-wrapper">
            <div className="villages">
                {gameData.villages.map((village, index) => {
                    const calcs = villageCalculations[index];

                    return <div className="village" key={index}>
                        <div className="village-stats">
                            <h3>{village.name}</h3>
                            <VariableDisplay fieldName="Population" fieldValue={village.population} fieldCalc={calcs.population}/>
                            <VariableDisplay fieldName="Militia" fieldValue={village.militia} fieldCalc={calcs.militia}/>
                        </div>
                        <table className="village-resources">
                            <tbody>
                                <tr>
                                    <td><img src={WheatImg} alt="wheat"/></td>
                                    <td><VariableDisplay fieldName="" fieldValue={village.potential.crop} fieldCalc={null} potential/></td>
                                    <td><VariableDisplay fieldName="" fieldValue={village.resources.food} fieldCalc={calcs.food}/></td>
                                </tr>
                                <tr>
                                    <td><img src={WoodImg} alt="wood"/></td>
                                    <td><VariableDisplay fieldName="" fieldValue={village.potential.lumber} fieldCalc={null} potential/></td>
                                    <td><VariableDisplay fieldName="" fieldValue={village.resources.wood} fieldCalc={calcs.wood}/></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="village-structures">
                            <VariableDisplay fieldName="Farms" fieldValue={village.structures.farms}/>
                        </div>
                    </div>
                })}
            </div>
            <div className="map">
                {[...Array(gameData.settings.mapSize)].map(n => <img className="tile" src={Hexagon} alt="Hex"/>)}
            </div>
        </div>
        : <button onClick={() => setGameData(GameDataDefault)}>New Game</button>}
    </div>
}

export default DivineFavorComponent;