import { FighterStats } from "../../types";

type BattleCardProps = {
    fighterData: FighterStats;
    onClick?: (e: any) => void;
}

const BattleCard = ({fighterData, onClick}: BattleCardProps) => {
    return <div className={`battle-card ${fighterData.color}`} onClick={onClick}>
        <div className="battle-card-header">
            <div className="battle-card-name">
                {fighterData.name}
            </div>
            <div className="battle-card-health">
                {fighterData.health}
            </div>
        </div>
        <div className="battle-card-avatar">
            <img src={fighterData.iconurl} alt="cute pic"/>
        </div>
        <div className="battle-card-details">
            <div className="battle-card-strengths">
                <div className="card-details-label">Strengths:</div>
                {fighterData.strengths && fighterData.strengths.map((str, index) =>
                    <div className="str-tooltip" key={index}>
                        {str}
                    </div>)
                }
            </div>
            <div className="battle-card-weaknesses">
                <div className="card-details-label">Weaknesses:</div>
                {fighterData.weaknesses && fighterData.weaknesses.map((wkn, index) =>
                    <div className="wkn-tooltip" key={index}>
                        {wkn}
                    </div>
                )}
            </div>
        </div>
    </div>
};

export default BattleCard;