import { useState } from "react";
import { TokiPonaWord } from "../../types";

type WordHintProps = {
    word: TokiPonaWord;
    children?: string;
}

const WordHint = ({word, children}: WordHintProps) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    return <span className="nimi-word"
        onMouseOver={() => setPopupVisible(true)}
        onMouseLeave={() => setPopupVisible(false)}>
            {popupVisible && <div className="nimi-definition-popup-wrapper">
                <div className="nimi-definition-popup">
                    <div className="nimi-header">{word.word}</div>
                    {Object.entries(word).map((value, index) => value[0] !== "id" && value[0] !== "word" &&
                        <div key={index} className="word-definition">
                            <span className="part-of-speech">{value[0]}: </span>
                            <span className="translation">{value[1]}</span>
                        </div>
                    )}
                </div>
            </div>}
            {children}
    </span>
}

export default WordHint;