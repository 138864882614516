import { apiCall } from "./store-util";

export const getCursors = () => {
    return apiCall("get-cursor-data").then((response: any) => {
        const res = JSON.parse(response.toString());
        res.map((cursor: any, index: number) => {
            res[index].strengths = JSON.parse(cursor.strengths);
            res[index].weaknesses = JSON.parse(cursor.weaknesses);
        });
        return res;
    });
}