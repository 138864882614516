import { TokiPonaWord } from "../../types";
import WordHint from "./WordHintComponent";

type NimiProps = {
    children?: string;
    dictionary: TokiPonaWord[];
}

const Nimi = ({children, dictionary}: NimiProps) => {
    const getWord = (word: string): TokiPonaWord|null => {
        if (dictionary && dictionary.length > 0) {
            const results = dictionary.filter(d => d.word === word.toLowerCase());
            if (results && results.length > 0) {
                return results[0];
            }
        }
        return null;
    };

    return <div className="nimi">{children?.split(" ").map((term, index) => {
        const word = getWord(term);
        return word ? [
            <WordHint key={index} word={word}>{term}</WordHint>
        , <span className="nimi-space" key={index + 1}> </span>] : null;
    })}</div>
}

export default Nimi;