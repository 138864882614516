import { useEffect, useState } from "react";
import { getTokiPonaWords } from "../../stores/toki-pona-store";
import { TokiPonaWord } from "../../types";
import Nimi from "./NimiComponent";
import QuizzerComponent from "./QuizzerComponent";
import "./TokiPona.scss";

const TokiPonaComponent = () => {
    const [dictionary, setDictionary] = useState<TokiPonaWord[]>([]);

    useEffect(() => {
        getTokiPonaWords().then((res) => {
            if (res) {
                setDictionary(res);
            }
        });
    }, []);

    return <div className="toki-pona-container">
        <Nimi dictionary={dictionary}>Toki Pona</Nimi>
        <p>Here's a demo idk. Try and memorize the word meanings but you can hover it for the answer</p>
        {dictionary && dictionary.length > 0 ? <QuizzerComponent words={dictionary}/>
        : <h1>loading</h1>}
    </div>
}

export default TokiPonaComponent;