import './App.css';
import IndexComponent from './Index/Index';

function App() {
  return (
    <IndexComponent/>
);
}

export default App;
