import Button from "../../common/Button/ButtonComponent";

const CSSQuickLinks = () => {

    return <div className="cssquicklinks">
        <Button href="https://www.cssmatic.com/box-shadow">CSS Box Shadow</Button>
        <Button href="https://cssgradient.io/">CSS Gradient</Button>
        <Button href="https://www.transition.style/">CSS Transition</Button>
    </div>
};

export default CSSQuickLinks;