import "./ButtonComponent.scss";

type ButtonProps = {
    children: any;
    href: string;
    blank?: boolean;
}

const Button = ({children, href, blank=true}: ButtonProps) => {
    return <div className="common-button" onClick={() => window.open(href, blank ? "_blank" : "")}>
        {children}
    </div>
}

export default Button;