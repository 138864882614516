import { useEffect, useState } from "react";
import BattleCard from "../common/BattleCard/BattleCard";
import {ReactComponent as PaintBrush} from "./img/icons/paint-brush-drawing-icon.svg";
import {ReactComponent as RightArrow} from "./img/icons/chevron-right-icon.svg";
import { FighterStats } from "../types";
import { getCursors } from "../stores/cursor-store";

type CustomizationPageProps = {
    onSetCursor: (e: any, cursor: any) => void;
    closeListener?: any;
}

const CustomizationPage = ({onSetCursor, closeListener}: CustomizationPageProps) => {
    const [isOpen, setIsOpen]               = useState<boolean>(false);
    const [cursorOptions, setCursorOptions] = useState<FighterStats[]>([]);

    useEffect(() => {
        getCursors().then((res) => {
            if (res) {
                setCursorOptions(res);
            }
        });
    }, []);

    useEffect(() => {
        setIsOpen(false);
    }, [closeListener]);

    return <div className={`customization-page-container ${isOpen ? "open" : "close"}`}>
        <div className="customization-open-tab" onClick={() => setIsOpen(true)}><PaintBrush/></div>
        <div className="customization-close-tab" onClick={() => setIsOpen(false)}><RightArrow/></div>
        <div className="categories">
            <div className="category-link">Cursors</div>
        </div>
        <div className="line-break"></div>
        <div className="reset-cursor-container">
            <div className="reset-cursor" onClick={(e) => onSetCursor(e, null)}>Reset Cursor</div>
        </div>
        <h3>Click one to change your mouse pointer.</h3>
        {cursorOptions && cursorOptions.length > 0 && <div className="battle-cards-wrapper">
            {cursorOptions.map((cursor, index) => 
                <BattleCard onClick={(e) => onSetCursor(e, cursor.pointerurl)}
                fighterData={cursor}
                key={index}/>
            )}
        </div>}
    </div>
}
export default CustomizationPage;