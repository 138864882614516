import { useState } from "react";
import { Variable } from "./wargame_types";

type VariableDisplayProps = {
    fieldName: string;
    fieldValue: number;
    fieldCalc?: Variable;
    potential?: boolean;
};

const VariableDisplay = ({fieldName, fieldValue, fieldCalc, potential = false}: VariableDisplayProps) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    return <div className="variable-display">
            {popupVisible && fieldCalc && <div className="variable-popup-wrapper">
                <div className="variable-popup">
                    {fieldCalc.rate.toFixed(2)} / s
                </div>
            </div>}
            <div onMouseOver={() => setPopupVisible(true)} onMouseLeave={() => setPopupVisible(false)}>
                {fieldName ? fieldName + ": " : ""}{fieldValue.toFixed(0)} {(fieldCalc?.cap) ? "/ " + fieldCalc?.cap.toFixed(0) : potential ? "%" : ""}
            </div>
    </div>
};

export default VariableDisplay;