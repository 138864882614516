import React, {useEffect, useRef, useState} from "react";
import ReactAudioPlayer from 'react-audio-player';
import { getThemes } from "../../stores/relax-tool-store";
import { RelaxTheme } from "../../types";

const RelaxComponent = () => {
    const containerRef = useRef<any>(null);
    const [selectedSound, setSelectedSound]   = useState<string>("");
    const [seconds, setSeconds]               = useState<number>(120);
    const [opacity, setOpacity]               = useState<number>(1);
    const [inputTimer, setInputTimer]         = useState<number>(0);
    const [hideTimer, setHideTimer]           = useState<boolean>(false);
    const [audioVolume, setAudioVolume]       = useState<number>(10);
    const [inputTriggered, setInputTriggered] = useState<boolean>(false);
    const [themes, setThemes]                 = useState<RelaxTheme[]>();
    const [gong]                              = useState<HTMLAudioElement>(
        new Audio("https://riley-davis.com/api/audio/gong-g.wav")
    );
    let tick: ReturnType<typeof setInterval>;
    let inputTick: ReturnType<typeof setInterval>;
    const listeners = ["mousedown", "keydown", "mousemove"];
    const handleInput = () => {
        setInputTriggered(true);
        setSeconds(120);
        setInputTimer(0);
    }

    const getVolume = () => {
        const vol = audioVolume / 100;
        return vol < 1 ? vol > 0 ? vol : 0 : 1;
    }

    const countdownTimer = () => {
        listeners.map((l) => document.addEventListener(l, handleInput));

        tick = setInterval(() => {
            setSeconds(s => {
                if( s <= 0 ) {
                    clearInterval(tick);
                    celebrate();
                } else {
                    return s - 1;
                }
                return s;
            });
        }, 1000);
    }

    useEffect(() => {
        countdownTimer();

        return () => {
            clearInterval(tick);
            listeners.map((l) => document.removeEventListener(l, handleInput));
        };
    }, [seconds, inputTimer && inputTimer < 0]);

    useEffect(() => {   
        if( inputTimer > 1 ) {
            setInputTriggered(false);
        }

        if( inputTimer > 5 ) {  
            setOpacity(0);
        } else {
            setOpacity(1);
        }
    }, [inputTimer, setInputTimer]);

    useEffect(() => {
        getThemes().then((res) => {
            if (res) {
                setThemes(res);
            }
        });

        inputTick = setInterval(() => {
            setInputTimer(t => t += 0.1);
        }, 100);

        return () => {
            clearInterval(inputTick);
            document.body.style.backgroundImage = "";
        };
    }, []);

    const celebrate = () => {
        gong.volume = getVolume();
        gong.play();
    }

    const buttonBackgroundStyling: React.CSSProperties = {
        backgroundSize: "cover",
        backgroundPosition: "center"
    };
    
    const handleChangeVolume = (element: any) => {
        setAudioVolume(parseInt( element.target.value ));
    }

    const handleThemeSelect = (theme: RelaxTheme) => {
        document.body.style.backgroundImage = "url('" + theme.background_image_url + "')";
        setSelectedSound(theme.audio_url);
    }

    return <div className={`relax-container`} ref={containerRef}>
        <p style={{opacity: opacity}}>Try to sit at your desk and not touch your computer or phone until the timer runs out.</p>
        <div className="timer-display" style={hideTimer ? {opacity: opacity} : {}}>
            <span className={`${inputTriggered && "red"}`}>
                {Math.floor( seconds / 60 ) + " : "}
                {seconds % 60 < 10 ? "0" : ""}
                {seconds % 60}
            </span>
        </div>
        <div className="options-container" style={{opacity: opacity}}>
            <div className="toggle-hide-timer">
                <label>Hide Timer</label>
                <input type="checkbox" value={hideTimer.toString()} onClick={() => setHideTimer(!hideTimer)}/>
            </div>
            <div className="control-volume">
                <label>Sound Volume</label>
                <input
                    type="range" min="1" max="100"
                    onInput={(value) => handleChangeVolume(value)}
                    value={audioVolume}/>
            </div>
        </div>
        <div className="theme-select" style={{opacity: opacity}}>
            <div className="theme-select-wrapper">
                {themes?.map((theme, index) =>
                    <div className="theme-select-button-container" key={index}>
                        <button
                            style={{backgroundImage: "url(" + theme.preview_image_url + ")", ...buttonBackgroundStyling}}
                            onClick={() => handleThemeSelect(theme)}>{theme.name}</button>
                    </div>
                )}
            </div>
        </div>
        <ReactAudioPlayer
            src={selectedSound}
            volume={getVolume()}
            autoPlay
            loop/>
    </div>
}

export default RelaxComponent;